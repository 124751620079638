<template>
  <div>
    <v-app>
      <v-container v-show="loading == false">
        <v-app-bar app color="white" elevation="0" height="80">
          <a :href="'https://app.pill.com.co/' + idCompany">
            <img :src="urlLogoCompany" :class="imageClass" @load="onImageLoad" ref="companyImage" alt="Company Logo" />
          </a>



          <v-spacer></v-spacer>

          <div style="padding: 0px 15px 0px">
            <a target="_blank" v-bind:href="social.url" v-for="social in socials" :key="social"
              style="text-decoration: none;">
              <v-btn v-if="social.url !== 'https://' & social.url !== 'https://instagram.com/'" icon>
                <v-icon :color="CompanyColor" size="30px">{{ social.icon }}</v-icon>
              </v-btn>
            </a>
          </div>
          <!--container con toda la info de la empresa-->
          <template v-slot:extension>
            <v-tabs centered :color="CompanyColor" v-model="tabs">
              <v-tab>Oportunidades</v-tab>
              <v-tab>Acerca de</v-tab>
            </v-tabs>
          </template>
        </v-app-bar>

        <v-tabs-items v-model="tabs" style="padding-top: 100px;">
          <!--Oportunidades TAB-->
          <v-tab-item class="screensize">
            <div v-show="trabajos.length == 0 & loading == false" style="max-width: 100%;" class="mx-auto">
              <h2 class="mb-8 mt-10"
                style="padding: 80px 0px 0px; text-align:center; color: black; font-size: normal; font-weight: 450;">
                <v-icon color="black" left>mdi-information-slab-circle-outline</v-icon>Aún no hay ninguna oportunidad
                publicada.
              </h2>
            </div>
            <v-container>
              <div v-for="trabajo in trabajos" :key="trabajo._id" style="padding: 10px 0px 0px;" class="mt-4">
                <v-card style="border-radius: 15px;" color="white" block elevation="2" outlined
                  @click="goToJob(trabajo._id)" class="custom-card">
                  <div style="padding: 10px 15px 0px;">
                    <v-btn depressed :color="CompanyColor" class="right" outlined x-small rounded>
                      {{ trabajo.category }}
                    </v-btn>
                  </div>
                  <div>

                    <v-list-item three-line>
                      <v-list-item-content style="max-width: 500px;">
                        <h2 style="font-size:1.25em; font-weight:600; word-break: normal;">{{ trabajo.role }}</h2>
                        <v-list-item-subtitle>
                          <v-icon left small :color="CompanyColor">mdi-map-marker</v-icon>{{ trabajo.modality }} -
                          {{ trabajo.country }}, {{ trabajo.city }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                  </div>
                </v-card>
              </div>
            </v-container>

          </v-tab-item>
          <!--Company info TAB-->
          <v-tab-item class="screensize">
            <center>
              <br>
              <h2 style="font-size:36px; font-weight: 600;">{{ nameCompany }}</h2>
              <p>
                <v-icon left small :color="CompanyColor"> mdi-map-marker </v-icon> {{ headquatersCompany }}
              </p>
              <v-row justify="center">
                <div v-for="industry in industries" :key="industry.index">
                  <v-btn outlined rounded small :color="CompanyColor" class="ma-2">{{ industry }}</v-btn>
                </div>
              </v-row>
              <br>
            </center>
            <v-card-text style="font-size:16px; text-align:justify; word-break:normal; ">
              <div v-html="styleHTML + HTMLDescription" />
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-container>



      <!--loader-->
      <v-container v-show="loading == true" style="height: 800px;">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            <center>
              <v-img src="https://d1kz8c63g8kbpa.cloudfront.net/web/Pill_Footer.png" max-height="100"
                max-width="100"></v-img>
            </center>

          </v-col>
          <v-col cols="6">
            <v-progress-linear color="#7579E7" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <br>
      <!-- <Footer v-show="loading == false" /> -->
      <div style="padding: 10px 0px 0px">
        <center>
          <a href="https://pill.com.co/">
            <v-img src='https://d153chakadu90d.cloudfront.net/web/Powered_pill.webp' max-height="100" max-width="100"
              alt="pill oportunidades logo"></v-img>
          </a>
        </center>
      </div>
      <br>
    </v-app>
  </div>
</template>

<script>
// Importar modulo "jobCompany" AXIOS
import axiosB2B_jobCompany from "@/logic/b2b/jobCompany/axios"
// Importar modulo "createCompany" AXIOS
import axiosB2B_createCompany from "@/logic/b2b/createCompany/axios"


export default {
  name: 'JobsTree-',
  components: {
  },
  data() {

    return {
      tabs: null,

      loading: true,

      trabajos: [],

      CompanyColor: '',
      urlLogoCompany: '',

      socials: [
        { name: "website", icon: "mdi-web", url: "" },
        { name: "linkedin", icon: "mdi-linkedin", url: "" },
        { name: "instagram", icon: "mdi-instagram", url: "" }

      ],
      industries: [],
      headquatersCompany: null,
      nameCompany: null,
      arrayTrabajos: null,
      tech: true,
      publishingDate: null,
      day: null,
      idCompany: '',

      //descripcion de la marca

      HTMLDescription: '',
      styleHTML: "<style>img{display: block;  margin-left: auto;  margin-right: auto; max-width: 100%; height: auto; width: auto;} @media screen and (max-width: 1904px) {iframe{ width: 80%;  height: 400px; display: block;  margin-left: auto;  margin-right: auto; }} @media screen and (max-width: 820px) {  iframe{ width: 100%;  height: 300px; margin-left: auto;  margin-right: auto; }}  @media screen and (max-width: 600px) { iframe{ width: 100%;  height: 250px; margin-left: auto;  margin-right: auto; }}  @media screen and (max-width: 360px) {  iframe{ width: 100%;  height: 200px; margin-left: auto;  margin-right: auto; }} </style>",

      pageTitle: '',

      imageClass: 'dynamic-image-box',
    }
  },

  watch: {
    pageTitle(newTitle) {
      document.title = newTitle || 'Pill ATS Jobs Board';
    }
  },

  async created() {

  },

  async mounted() {

    // 1. GET Data Company ALLB2B
    const respDataCompany = await axiosB2B_createCompany.getDataCompany(this.$route.params.id_company)
    if (respDataCompany.data.code == "success") {

      // Asigno data de la company
      // Data general
      this.idCompany = respDataCompany.data.message.id_company;
      this.CompanyColor = respDataCompany.data.message.color_company
      this.urlLogoCompany = respDataCompany.data.message.url_logo_company
      this.nameCompany = respDataCompany.data.message.name_company
      this.headquatersCompany = respDataCompany.data.message.headquaters_company
      this.industries = respDataCompany.data.message.industry
      this.HTMLDescription = respDataCompany.data.message.description_company

      // Tab Name
      this.pageTitle = respDataCompany.data.message.name_company + " Jobs";
      this.updateFavicon(respDataCompany.data.message.url_logo_company);

      // Data redes sociales
      this.socials[0].url = respDataCompany.data.message.website_company
      this.socials[1].url = respDataCompany.data.message.linkedin_company
      this.socials[2].url = respDataCompany.data.message.instagram_company

      // 2. Consulto el Job Tree ACTIVE
      const respJobTree = await axiosB2B_jobCompany.getAllJobsTreeActive(this.$route.params.id_company, 1, 50);

      if (respJobTree.data.code == "success") {
        // Asigno al array de Job Tree
        this.trabajos = respJobTree.data.message.docs;

      } else {
        console.log("ERROR API= ", respJobTree)
      }
    } else {
      console.log("ERROR API= ", respDataCompany)
    }

    this.loading = false;

  },

  methods: {

    onImageLoad(e) {
      const image = e.target
      if (image) {
        const { naturalWidth, naturalHeight } = image;
        console.log("Tamaño Imagen")
        console.log(naturalWidth, naturalHeight)
        if (naturalWidth !== naturalHeight) {
          this.imageClass = 'dynamic-image-rectangle';
        } else {
          this.imageClass = 'dynamic-image-box';
        }
      }
    },


    updateFavicon(logoUrl) {
      const faviconUrl = logoUrl || 'https://d1kz8c63g8kbpa.cloudfront.net/web/pillFavicon.png';

      // Eliminar favicon existente
      const existingFavicon = document.querySelector('link[rel="icon"]');
      if (existingFavicon) {
        existingFavicon.parentNode.removeChild(existingFavicon);
      }

      // Crear y agregar nuevo favicon
      const favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.type = 'image/png';
      favicon.href = faviconUrl;
      document.head.appendChild(favicon);

      console.log(`Favicon actualizado a: ${faviconUrl}`);
    },
    goToJob(id) {
      this.$router.push('/' + this.idCompany + '/' + id)
    }
  }

}
</script>
<style scoped>
.v-tab {
  text-transform: none;
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1904px) {
  .screensize {
    /* padding: 0px 0px 0px !important; */
    margin-right: 240px;
    margin-left: 240px;
    /* background-color: blueviolet; */
  }

  .img-empty {
    width: 15%
  }
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 820px) {
  .screensize {
    /* padding: 0px 0px 0px !important; */
    margin-right: 100px;
    margin-left: 100px;
    /* background-color: yellow; */
  }

  .img-empty {
    width: 15%
  }

  .buttonPosition {
    max-width: 30%;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .screensize {
    /* padding: 0px 0px 0px; */
    margin-right: 0px;
    margin-left: 0px;
    /* background-color: red; */
  }

  .img-empty {
    width: 25%
  }

  .buttonPosition {
    max-width: 35%;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 360px) {
  .screensize {
    /* padding: 0px 0px 0px; */
    margin-right: 0px;
    margin-left: 0px;
    /* background-color: green; */
  }

  .img-empty {
    width: 25%
  }

  .buttonPosition {
    max-width: 38%;
  }
}

.custom-card:hover {
  background-color: red;
  /* Change this to the desired color on hover */
}

.dynamic-image-box {
  max-width: 75px;
  max-height: 75px;
  width: auto;
  height: auto;
}

.dynamic-image-rectangle {
  max-width: 115px;
  max-height: 80px;
  width: auto;
  height: auto;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  /* Ajusta el límite máximo */
  max-height: 100px;
  /* Ajusta el límite máximo */
  overflow: hidden;
  /* Evita que sobresalgan las imágenes */
}

.logo {
  object-fit: contain;
  /* Mantiene la proporción de la imagen */
}
</style>