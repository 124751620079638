import axios from "axios";

const ENDPOINT_API_v2 = "https://vl7vvkdhrjtx7bgv27jicumlfi0dyxto.lambda-url.us-east-2.on.aws/api/v2"


export default {

    async registerJobB2B(id_company, country, city, role, modality, experience, description, companyLogo, companyColor, category, email_recruiter, status, openedAt, expirationDate, employmentType, completeSalary, maxNumber) {
        const newregisterJobB2B = {
            id_company: id_company,
            country: country,
            city: city,
            role: role,
            modality: modality,
            experience: experience,
            description: description,
            companyLogo: companyLogo,
            companyColor: companyColor,
            category: category,
            email_recruiter: email_recruiter,
            status: status,
            openedAt: openedAt,
            expirationDate: expirationDate,
            employmentType: employmentType,
            completeSalary: completeSalary,
            maxNumber: maxNumber           
        }
        // const headers = {
        //     headers: { Authorization: 'Bearer ' + await authFirebase.createTokenJWT() }
        // };
        const resp = await axios.post(ENDPOINT_API_v2 + "/jobs/create", newregisterJobB2B)
        return resp;
    },

    async updateJobCompany(id_company, id_job, country, city, role, modality, experience, description, companyLogo, companyColor, category, email_recruiter, status, openedAt, expirationDate, employmentType, completeSalary, maxNumber) {

        const params = {
            id_company: id_company,
            country: country,
            city: city,
            role: role,
            modality: modality,
            experience: experience,
            description: description,
            companyLogo: companyLogo,
            companyColor: companyColor,
            category: category,
            email_recruiter: email_recruiter,
            status: status,
            openedAt: openedAt,
            expirationDate: expirationDate,
            employmentType: employmentType,
            completeSalary: completeSalary,
            maxNumber: maxNumber  
        }

        const resp = await axios.put(ENDPOINT_API_v2 + "/jobs/update/"+ id_job, params)
        return resp;
    },

    async updateAccountPlan(email_company, plan, ctm) {

        const params = {
            email: email_company,
            p: plan,
            ctm: ctm
        }

        const resp = await axios.put(ENDPOINT_API_v2 + "/accounts/update", params)
        return resp;
    },

    async updateJobStatus(id_job, status) {

        const params = {
            id_job: id_job,
            status_job: status
        }

        const resp = await axios.put(ENDPOINT_API_v2 + "/update/status", params)
        return resp;
    },

    async updateAccountUsageJobs(id_company) {

        const params = {
            id_company: id_company
        }

        const resp = await axios.put(ENDPOINT_API_v2 + "/accounts/update/usage/jobs", params)
        return resp;
    },

    async updateAccountUsageApplicants(id_company) {

        const params = {
            id_company: id_company
        }

        const resp = await axios.put(ENDPOINT_API_v2 + "/accounts/update/usage/applicants", params)
        return resp;
    },

    async updateJobPromoted(url_job) {

        const params = {
            url_job: url_job
        }

        const resp = await axios.put(ENDPOINT_API_v2 + "/update/promoted", params)
        return resp;
    },

}
