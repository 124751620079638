<template>
  <div>
    <v-app>
      <Header :urllogo="urlLogoCompany" :idCompany="idCompany" />

      <v-container v-show="loading == true" style="height: 800px;">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            <center>
              <v-img src="https://d153chakadu90d.cloudfront.net/web/Powered_pill.webp" max-height="100" max-width="100"
                alt="pill oportunidades logo"></v-img>
            </center>
          </v-col>
          <v-col cols="6">
            <v-progress-linear color="#7579E7" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <v-main v-show="loading == false" class="screensize">
        <v-card class="mt-4 ml-1 mr-1 mb-1" style="border-radius: 20px;">
          <v-row>
            <v-col>
              <a :href="'https://app.pill.com.co/' + idCompany" style="text-decoration: none;">
                <v-btn class="ml-3 mt-2 elevation-0" small color="white"><v-icon left
                    x-small>mdi-arrow-left</v-icon>Volver</v-btn>
              </a>
            </v-col>
            <v-col style="text-align: end;">
              <a v-show="statusPause == false" :href="arrayTrabajos._id + '/apply'" style="text-decoration: none;">
                <v-btn small :color="CompanyColor" class="white--text right mt-2 mr-3" rounded :disabled="statusPause"
                  style="width: 150px;">
                  Aplicar
                </v-btn>
              </a>
            </v-col>
          </v-row>
          <v-card class="mx-auto mt-5" :style="{ 'cssText': generarRadialGradient(CompanyColor) }" outlined>
            <v-btn small class="ma-2 elevation-0 white--text" :color="CompanyColor" rounded><v-icon small
                left>mdi-account-multiple</v-icon>{{ arrayTrabajos.numApplication }} Aplicantes</v-btn>
            <div class="mx-auto" style="max-width: 80%;">
              <h2 style="color: white; text-align: center; font-weight: 600;" class="mt-2 title-size">{{ arrayTrabajos.role }}</h2>
            </div>
            <center>
              <v-btn small class="mx-auto elevation-0 ma-2" rounded color="white"><v-icon left x-small
                  :color="CompanyColor">mdi-map-marker</v-icon>{{ arrayTrabajos.modality }} - {{ arrayTrabajos.country }},
                {{ arrayTrabajos.city }}</v-btn>
            </center>
            <br>
          </v-card>

          <center>
            <v-btn v-show="statusPause == true" small class="ma-2" color="red" outlined depressed rounded><v-icon left
                small color="red">mdi-cancel</v-icon><span style="color: red;">No se aceptan mas
                solicitudes</span></v-btn>
          </center>
          <v-card-text style="background-color: #ffffff">
            <v-row>
              <v-col>
              </v-col>
              <v-col style="text-align: end; max-width: fit-content;">
                <a :href="linkShare + arrayTrabajos.role + ' '  + arrayTrabajos.country + ' - ' + arrayTrabajos.city +' '+  applyLink + arrayTrabajos.id_company + '/' + arrayTrabajos._id" style="text-decoration: none;">
                  <v-btn icon><v-icon color="black">mdi-whatsapp</v-icon></v-btn>
                </a>
                <a :href="'https://twitter.com/intent/tweet?text='+ arrayTrabajos.role + ' '  + arrayTrabajos.country + ' - ' + arrayTrabajos.city +' '+ 'app.pill.com.co/' + arrayTrabajos.id_company + '/' + arrayTrabajos._id"
                  style="text-decoration: none;">
                  <v-btn icon><v-icon color="black">mdi-twitter</v-icon></v-btn>
                </a>
                <a :href="'http://www.linkedin.com/shareArticle?mini=true&url=https://' + 'app.pill.com.co/' + arrayTrabajos.id_company + '/' + arrayTrabajos._id"
                  style="text-decoration: none;">
                  <v-btn icon><v-icon color="black">mdi-linkedin</v-icon></v-btn>
                </a>
              </v-col>
            </v-row>
            <center>

            </center>
            <div style="line-height:50%" class="mt-2 mb-7">
              <p class="ml-2" style="font-size:15px;"><span style="font-weight: 500; color: black;">Publicación:</span> {{publishingDate}}</p>
              <p class="ml-2" style="font-size:15px;"><span style="font-weight: 500; color: black;">Modalidad:</span> {{arrayTrabajos.modality }}</p>
              <p class="ml-2" style="font-size:15px;"><span style="font-weight: 500; color: black;">Experiencia:</span> {{arrayTrabajos.experience}}</p>
              <p class="ml-2" style="font-size:15px;"><span style="font-weight: 500; color: black;" v-if="arrayTrabajos.employmentType">Tipo:</span> {{arrayTrabajos.employmentType}}</p>
              <p class="ml-2" style="font-size:15px;"><span style="font-weight: 500; color: black;" v-if="arrayTrabajos.completeSalary">Salario:</span>  {{ arrayTrabajos.completeSalary }}</p>
            </div>
            <p style="font-size: 15px; font-weight: 600; color: black;" class="mt-2 ml-2">Descripción Oportunidad:</p>

            <div class="ml-2 mr-2" style="text-align:justify; font-size:16px; word-break:normal; color: black;"
              v-html="styleHTML + HTMLDescription" />

            <v-card-actions>
              <v-spacer></v-spacer>
              <a v-show="statusPause == false" :href="arrayTrabajos._id + '/apply'" style="text-decoration: none;">
                <v-btn :color="CompanyColor" class="white--text hidden-sm-and-down" rounded :disabled="statusPause"
                  style="width: 200px;">
                  Aplicar
                </v-btn>
              </a>
            </v-card-actions>


          </v-card-text>
          <center>
            <a v-show="statusPause == false" :href="arrayTrabajos._id + '/apply'" style="text-decoration: none;">
              <v-btn :color="CompanyColor" class="white--text hidden-md-and-up mb-4" rounded :disabled="statusPause"
                style="width: 200px;">
                Aplicar
              </v-btn>
            </a>
          </center>

        </v-card>


      </v-main>
      <div style="padding: 10px 0px 0px" v-show="loading == false">
        <center>
          <a href="https://pill.com.co/">
            <v-img src='https://d153chakadu90d.cloudfront.net/web/Powered_pill.webp' max-height="100" max-width="100"
              alt="pill oportunidades logo"></v-img>
          </a>
        </center>
      </div>

    </v-app>

  </div>
</template>
  
<script>

// import Footer from '@/components/components_jobs_empresas/footerJobs.vue'
import Header from '@/components/components_jobs_empresas/headerJobs.vue'

// Importar modulo "jobCompany" AXIOS
import axiosB2B_jobCompany from "@/logic/b2b/jobCompany/axios"


export default {
  name: 'singleTrabajoView',
  components: {
    Header,
    // Footer,
  },

  data() {
    return {
      loading: true,
      linkShare: 'https://api.whatsapp.com/send?text=',
      applyLink: 'https://app.pill.com.co/',
      // arrayTrabajos: null,
      arrayTrabajos: {},
      CompanyColor: "#000000",
      CompanyColorGradient: "",
      urlLogoCompany: '',
      HTMLDescription: '',
      styleHTML: "<style>img{display: block;  margin-left: auto;  margin-right: auto; max-width: 100%; height: auto; width: auto;} @media screen and (max-width: 1904px) {iframe{ width: 80%;  height: 400px; display: block;  margin-left: auto;  margin-right: auto; }} @media screen and (max-width: 820px) {  iframe{ width: 100%;  height: 300px; margin-left: auto;  margin-right: auto; }}  @media screen and (max-width: 600px) { iframe{ width: 100%;  height: 250px; margin-left: auto;  margin-right: auto; }}  @media screen and (max-width: 360px) {  iframe{ width: 100%;  height: 200px; margin-left: auto;  margin-right: auto; }} </style>",

      // publishingDate: null,
      publishingDate: '',
      day: null,
      idCompany: '',

      //status
      statusPause: false,
      status: "",

      pageTitle: '',


    }
  },

  watch: {
    pageTitle(newTitle) {
      document.title = newTitle || 'Pill ATS Jobs Board';
    }
  },

  methods: {
    generarRadialGradient(color) {
      let css = `
        background: radial-gradient(at bottom right, ${color} 0, ${color} 20px, ${color + "33"} 20px, ${color + "33"} 40px, ${color + "BF"} 40px, ${color + "BF"} 60px, ${color + "40"} 60px, ${color + "40"} 80px, ${color + "4D"} 80px, ${color + "4D"} 100px, ${color + "BF"} 100px, ${color + "BF"} 120px, ${color + "33"} 120px, ${color + "33"} 140px, transparent 140px, transparent 160px), radial-gradient(at top left, transparent 0, transparent 20px, ${color + "33"} 20px, ${color + "33"} 40px, ${color + "BF"} 40px, ${color + "BF"} 60px, ${color + "4D"} 60px, ${color + "4D"} 80px, ${color + "40"} 80px, ${color + "40"} 100px, ${color + "BF"} 100px, ${color + "BF"} 120px, ${color + "33"} 120px, ${color + "33"} 140px, ${color} 140px, ${color} 160px, transparent 160px, transparent 400px);
        background-blend-mode: multiply;
        background-size: 160px 160px, 160px 160px;
        background-color: ${color + "D4"};
        width: 95%; height: fit-content; border-radius: 10px;
      `;



      return css;
    },
    updateFavicon(logoUrl) {
      const faviconUrl = logoUrl || 'https://d1kz8c63g8kbpa.cloudfront.net/web/pillFavicon.png';

      // Eliminar favicon existente
      const existingFavicon = document.querySelector('link[rel="icon"]');
      if (existingFavicon) {
        existingFavicon.parentNode.removeChild(existingFavicon);
      }

      // Crear y agregar nuevo favicon
      const favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.type = 'image/png';
      favicon.href = faviconUrl;
      document.head.appendChild(favicon);

      console.log(`Favicon actualizado a: ${faviconUrl}`);
    }
  },
  async mounted() {

    // 1. Solicitar los datos para un Single Job de esa Company
    const respSingleJob = await axiosB2B_jobCompany.getSingleJobCompany(this.$route.params.id_company, this.$route.params.id_job)

    if (respSingleJob.data.code == "success") {
      // console.log(respSingleJob.data.message);
      this.idCompany = respSingleJob.data.message.id_company
      this.arrayTrabajos = respSingleJob.data.message;
      this.CompanyColor = respSingleJob.data.message.companyColor
      this.CompanyColorGradient = respSingleJob.data.message.companyColor + "95"
      this.urlLogoCompany = respSingleJob.data.message.companyLogo
      this.HTMLDescription = respSingleJob.data.message.description
      this.publishingDate = this.arrayTrabajos.openedAt.substring(0, 10)
      this.numApplication = respSingleJob.data.message.numApplication
      this.status = respSingleJob.data.message.status

      // Tab Name
      this.pageTitle = this.arrayTrabajos.role ;
      this.updateFavicon(respSingleJob.data.message.companyLogo);

      if (this.status != 'ACTIVE') {
        this.statusPause = true;
      }
      else {
        this.statusPause = false;
      }

      this.loading = false;

    } else {
      console.log("ERROR API= ", respSingleJob)
    }

  },

}
</script>

<style scoped>
.v-main {
  overflow-x: hidden !important;
}

.v-btn {
  text-transform: none;
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1904px) {
  .screensize {
    padding: 80px 0px 0px !important;
    margin-right: 240px;
    margin-left: 240px;
    /* background-color: blueviolet; */
  }

  .title-size {
    font-size: 35px;
  }
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 820px) {
  .screensize {
    padding: 80px 0px 0px !important;
    margin-right: 100px;
    margin-left: 100px;
    /* background-color: yellow; */
  }

  .title-size {
    font-size: 25px;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .screensize {
    padding: 80px 0px 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* background-color: red; */
  }

  .title-size {
    font-size: 25px;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 360px) {
  .screensize {
    padding: 80px 0px 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* background-color: green; */
  }

  .title-size {
    font-size: 20px;
  }
}

.tutorials-card {
  background: radial-gradient(at bottom right, #000000 0, #000000 20.5px, rgba(0, 0, 0, 0.2) 20.5px, rgba(0, 0, 0, 0.2) 41px, rgba(0, 0, 0, 0.75) 41px, rgba(0, 0, 0, 0.75) 61.5px, rgba(0, 0, 0, 0.25) 61.5px, rgba(0, 0, 0, 0.25) 82px, rgba(0, 0, 0, 0.3) 82px, rgba(0, 0, 0, 0.3) 102.5px, rgba(0, 0, 0, 0.75) 102.5px, rgba(0, 0, 0, 0.75) 123px, rgba(0, 0, 0, 0.2) 123px, rgba(0, 0, 0, 0.2) 143.5px, transparent 143.5px, transparent 164px), radial-gradient(at top left, transparent 0, transparent 20.5px, rgba(0, 0, 0, 0.2) 20.5px, rgba(0, 0, 0, 0.2) 41px, rgba(0, 0, 0, 0.75) 41px, rgba(0, 0, 0, 0.75) 61.5px, rgba(0, 0, 0, 0.3) 61.5px, rgba(0, 0, 0, 0.3) 82px, rgba(0, 0, 0, 0.25) 82px, rgba(0, 0, 0, 0.25) 102.5px, rgba(0, 0, 0, 0.75) 102.5px, rgba(0, 0, 0, 0.75) 123px, rgba(0, 0, 0, 0.2) 123px, rgba(0, 0, 0, 0.2) 143.5px, #000000 143.5px, #000000 164px, transparent 164px, transparent 410px);
  background-blend-mode: multiply;
  background-size: 164px 164px, 164px 164px;
  background-color: rgba(0, 0, 0, 0.77);
}

.tutorials-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  /* Agrega una sombra para el efecto de elevación */
  cursor: pointer;
  /* Cambia el cursor para indicar que se puede hacer clic */
}
</style>