<template>
    <!-- If using vue-router -->
    <router-view>
      <router-view />
      <notifications group="foo" />
    </router-view>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style>
/* Los estilos de la barra de desplazamiento */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
