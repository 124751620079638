<template>
  <div>
    <v-app>
      <Header :urllogo="urlLogoCompany" :idCompany="idCompany" />
      <v-main class="screensize">
        <div v-show="successApplication == false">
          <center>
            <br>
            <h2 style="font-size:36px; font-weight: 600">{{ arrayTrabajos.role }}</h2>
            <v-btn small class="mx-auto elevation-0 ma-2 mb-7" rounded color="#f9f9f9"><v-icon left x-small
                  :color="CompanyColor">mdi-map-marker</v-icon>{{ arrayTrabajos.modality }} - {{ arrayTrabajos.country }},
                {{ arrayTrabajos.city }}</v-btn>
          </center>
          
        </div>
        <!--formulario de aplicacion-->
        <v-card-text v-show="successApplication == false" style="background-color: #f9f9f9; border-radius: 20px;">
          <form ref="form" @submit.prevent="apply()">
            <!-- PRIMERA PARTE  -->

            <section>

              <center>
                <p style="color:rgb(24, 24, 24); font-weight: 500;">Nombre Completo</p>
                <v-text-field style="max-width:450px; border-radius: 10px" v-model="name" label="" type="text" :color="CompanyColor" required
                  solo outlined dense>
                </v-text-field>
                <p style="color:rgb(24, 24, 24); font-weight: 500;">Numero de WhatsApp</p>
                <VuePhoneNumberInput id="phoneNumber1" v-model="phoneNumber" :dark="dark" :disabled="disabled"
                  :default-country-code="defaultCountry" :ignored-countries="countriesIgnored"
                  :preferred-countries="countriesList" :loader="hasLoaderActive" clearable :error="hasErrorActive"
                  class="mb-2 elevation-3" style="max-width: 450px; ; border-radius: 10px" @update="onUpdate1" />
                <br>

                <p style="color:rgb(24, 24, 24); font-weight: 500;">Email</p>
                <v-text-field style="max-width:450px; border-radius: 10px" :rules="emailRules" v-model="email" label="" type="email" required
                  solo outlined dense :color="CompanyColor"></v-text-field>

                <p style="color:rgb(24, 24, 24); font-weight: 500;">Hoja de vida</p>
                <v-file-input small-chips counter show-size style="max-width:450px; border-radius: 10px" label="Carga aqui tu HV" outlined solo
                  v-model="file" v-on:change="handleFile()" accept=".pdf" :color="CompanyColor"></v-file-input>

                <p style="color:rgb(24, 24, 24); font-weight: 500;">LinkedIn (Opcional)</p>
                <v-text-field style="max-width:450px; border-radius: 10px" v-model="linkedinURL" label="" type="text" solo outlined dense
                  :color="CompanyColor">
                </v-text-field>
              </center>
            </section>
            <center>
              <p style="font-size: smaller; width: 90%;">By applying to this job through <b>{{ this.idCompany }}</b> powered
                by Pill, you agree to the Terms of Service and Privacy Policy of both
                <b>{{ this.idCompany }}</b> and <b>Pill.com.co</b>.</p>
              <v-btn type="submit" class="white--text mt-4" rounded :loading="loadingApply" :disabled="loadingApply || !noPDFfile"
                :color="CompanyColor" style="width: 150px;">Enviar
              </v-btn>
            </center>
          </form>
        </v-card-text>
        <!--confirmacion de envio-->
        <div style="padding: 20px 0px 0px" v-show="successApplication == true">
          <center>
            <h3 class="mr-5 ml-5" style="font-weight:400">Haz aplicado correctamente a: </h3>
              <h2 class="mt-7" style="font-size:26px">{{ arrayTrabajos.role }}</h2>
              <p class="mb-5">
                <v-icon left small :color="CompanyColor"> mdi-map-marker </v-icon>{{ arrayTrabajos.modality }},
                {{ arrayTrabajos.city }}
              </p>
            <h2 class="ml-5 mr-5 mb-8"
              style="padding: 50px 0px 0px; text-align:center; color: #25D366; font-size: xx-large; font-weight: 500;">
              <v-icon color="#25D366" class="mr-2">mdi-check-circle</v-icon>Aplicación Exitosa!
            </h2>

            <div style="padding: 50px 0px 0px">
              <a :href="'https://app.pill.com.co/'+ this.idCompany" style="text-decoration: none;">
                <v-btn large class="white--text mt-4" rounded :color="CompanyColor">Ver más oportunidades</v-btn>
              </a>
            </div>
            <br>

          </center>
        </div>
      </v-main>
      <Footer />
      <br>


    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/components_jobs_empresas/headerJobs.vue'
import Footer from '@/components/components_jobs_empresas/footerJobs.vue'
import emailNotifications from "@/logic/emailNotifications";

// Importar modulo "jobCompany" AXIOS
import axiosB2B_jobCompany from "@/logic/b2b/jobCompany/axios"
// Importar modulo "applicationJob" AXIOS
import axiosB2B_applicationJob from "@/logic/b2b/applicationJob/axios"
// Importar modulo "applicationJob" AXIOS
import axiosB2B_dataCompany from "@/logic/b2b/createCompany/axios"
// Importar modulo "axiosCLOUD_s3"
import axiosCLOUD_s3 from "@/logic/cloud/s3/axios"
//Importar modulo backend v2
import jobsBackendV2 from "@/logic/backend_v2/jobsBackend"

import VuePhoneNumberInput from "vue-phone-number-input";

import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

let formData = new FormData();
export default {
  name: 'Application-',
  components: {
    Header,
    Footer,
    VuePhoneNumberInput,
  },
  data() {

    return {

      successApplication: false,

      //variables de componente phone number
      phoneNumber: null,
      defaultCountry: "CO",
      countriesList: ["CO", "US", "AR", "BO", "BR", "CL", "EC", "PY", "PE", "UR"],
      countriesIgnored: [],
      dark: false,
      disabled: false,
      hasLoaderActive: false,
      hasErrorActive: false,

      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],

      //variables de formulario
      name: "",
      email: "",
      file: null,
      noPDFfile: false,
      linkedinURL: "",
      websiteURL: "",
      urlCV: "",

      // Variables de Header
      arrayTrabajos: {},
      CompanyColor: "#000000",
      urlLogoCompany: '',
      resultsPhone: {},
      idCompany: "",
      emailRecruiter: "",


      //adicionales
      maxNumber: null,


      //laoding boton aplicar

      loadingApply: false,


    };
  },
  async created() {

  },
  async mounted() {

    // 1. Solicitar los datos para un Single Job de esa Company
    const respSingleJob = await axiosB2B_jobCompany.getSingleJobCompany(this.$route.params.id_company, this.$route.params.id_job)

    if (respSingleJob.data.code == "success") {
      console.log(respSingleJob);
      this.idCompany = respSingleJob.data.message.id_company
      this.arrayTrabajos = respSingleJob.data.message;
      this.CompanyColor = respSingleJob.data.message.companyColor
      this.urlLogoCompany = respSingleJob.data.message.companyLogo
      this.HTMLDescription = respSingleJob.data.message.description
      this.publishingDate = this.arrayTrabajos.openedAt.substring(0, 10)
      this.numApplication = respSingleJob.data.message.numApplication
      this.maxNumber = respSingleJob.data.message.maxNumber ?? 40;
      this.loading = false;

    } else {
      console.log("ERROR API= ", respSingleJob)
    }


    // 2. Solicitar los datos para un Single Job de esa Company
    const respDataCompany = await axiosB2B_dataCompany.getDataCompany(this.$route.params.id_company)

    if (respDataCompany.data.code == "success") {
      // console.log(resp.data.message);
      this.idCompany = respDataCompany.data.message.id_company
      this.nameCompany = respDataCompany.data.message.name_company
      this.loading = false;

    } else {
      console.log("ERROR API= ", respDataCompany)
    }

  },
  methods: {

    generarRadialGradient(color) {
      let css = `
        background: radial-gradient(at bottom right, ${color} 0, ${color} 20px, ${color + "33"} 20px, ${color + "33"} 40px, ${color + "BF"} 40px, ${color + "BF"} 60px, ${color + "40"} 60px, ${color + "40"} 80px, ${color + "4D"} 80px, ${color + "4D"} 100px, ${color + "BF"} 100px, ${color + "BF"} 120px, ${color + "33"} 120px, ${color + "33"} 140px, transparent 140px, transparent 160px), radial-gradient(at top left, transparent 0, transparent 20px, ${color + "33"} 20px, ${color + "33"} 40px, ${color + "BF"} 40px, ${color + "BF"} 60px, ${color + "4D"} 60px, ${color + "4D"} 80px, ${color + "40"} 80px, ${color + "40"} 100px, ${color + "BF"} 100px, ${color + "BF"} 120px, ${color + "33"} 120px, ${color + "33"} 140px, ${color} 140px, ${color} 160px, transparent 160px, transparent 400px);
        background-blend-mode: multiply;
        background-size: 160px 160px, 160px 160px;
        background-color: ${color + "D4"};
        width: 95%; height: fit-content; border-radius: 10px;
      `;



      return css;
    },

    // async handleFile() {
    //   if (this.file != null) {
    //     formData.set('file', this.file);
    //   }
    // },
    

    async handleFile() {
            if (this.file != null) {
                formData.set('file', this.file);
                console.log(this.file, formData)
                if (this.file.type === "application/pdf") {
                    console.log("Si es un PDF")
                    if (this.file.size < 3000000) {
                        console.log("todo bien")
                        this.noPDFfile = true
                    }
                    else {
                        console.log("ES un PDF pero mayor a 3MB :(")
                        this.noPDFfile = false
                        this.file = null
                        Vue.$toast.open({
                            message: '<p style="font-family: poppins;">Carga un archivo menor a <b>3MB</b>.</p>',
                            type: 'error',
                            position: 'top',
                            duration: 6000
                        });
                    }
                }
                else {
                    console.log("NO es un PDF")
                    this.noPDFfile = false
                    this.file = null
                    Vue.$toast.open({
                        message: '<p style="font-family: poppins;">Porfavor carga un archivo <b>.pdf</b></p>',
                        type: 'error',
                        position: 'top',
                        duration: 8000
                    });
                }
            }
        },

    removeAcentsS3(str) {
      str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "_");
      return str
    },

    async apply() {
      this.loadingApply = true //activa loader del boton

      // 1. Verificar si ya se aplico al job
      const respVerify = await axiosB2B_applicationJob.verifyApplicationJob(this.$route.params.id_company, this.$route.params.id_job, this.name, this.resultsPhone.formattedNumber, this.email, "https://" + this.linkedinURL, "https://" + this.websiteURL)

      if (respVerify.data.code == "repeated") {
        this.loadingApply = false //desactiva loader del boton
        // Caso de aplicacion repetida
        Vue.$toast.open({
          message: '<p style="font-family: poppins;">Oops! Ya aplicaste a esta oportunidad.</p>',
          type: 'warning',
          position: 'top',
          duration: 7000
        });

      } else if (respVerify.data.code == "success") { // Caso de habilitado para registro

        // 1. Enviar PDF a s3 para despertar la API y limpiar nombre para almacenar en s3 de forma adecuada
        const nameFileS3 = this.removeAcentsS3(this.name)
        const respUploadFiles3 = await axiosCLOUD_s3.uploadFileS3(formData, "companies/" + this.$route.params.id_company, "applications/jobs/" + this.$route.params.id_job, nameFileS3)

        if (respUploadFiles3.data.code == "success") {
          this.urlCV = respUploadFiles3.data.location

          //2. Enviar correo al aplicante
          const sendEmailNotications = await emailNotifications.emailNewApplicant(this.idCompany, this.nameCompany, this.urlLogoCompany, this.arrayTrabajos.role, this.email)
          // console.log(sendEmailNotications )
          if (sendEmailNotications.status == 200) {

            // 3. Crear aplicación en Mongo
            const respApplicationJob = await axiosB2B_applicationJob.createApplicationJob(this.$route.params.id_company, this.$route.params.id_job, this.name, this.resultsPhone.formattedNumber, this.email, this.urlCV, "https://" + this.linkedinURL, "https://" + this.websiteURL)

            if (respApplicationJob.data.code == "success") {

              // 4. Actualizar agregaciones del Job
              const resp_aggregationJob = await axiosB2B_applicationJob.setAggregationJob(this.$route.params.id_company, this.$route.params.id_job)

              // 4. Actualizar uso de aplicantes Plan
              const respAggregationAccountUsage = await jobsBackendV2.updateAccountUsageApplicants(this.$route.params.id_company)
              if (respAggregationAccountUsage.data.success == true ){
                console.log("Usage Updated")
              }
              if (resp_aggregationJob.data.code == "success") {

                if (this.numApplication + 1 >= this.maxNumber) {
                  const respStatusJob = await jobsBackendV2.updateJobStatus(this.$route.params.id_job, "PAUSE")
                  if (respStatusJob.data.success == true) {

                      console.log("limite de candidatos alcanzado")
                  } else {
                      console.log("ERROR API= ", respStatusJob)
                  }
                }
                
                this.$refs.form.reset(); // clear the free input spaces from the form
                this.phoneNumber = null
                this.file = null
                this.loadingApply = false //desactiva loader del boton
                this.successApplication = true
                Vue.$toast.open({
                  message: '<p style="font-family: poppins;">¡Enviado! Mucha suerte.</p>',
                  type: 'success',
                  position: 'top',
                  duration: 7000
                });
              } else {
                console.log("ERROR AGREGATION= ", resp_aggregationJob)
                this.loadingApply = false //desactiva loader del boton
                Vue.$toast.open({
                  message: '<p style="font-family: poppins;">Oops! Algo fallo, intenta más tarde.</p>',
                  type: 'error',
                  position: 'top',
                  duration: 7000
                });
              }
            } else {
              console.log("ERROR APPLICATION MONGO= ", respApplicationJob)
            }
          } else {
            console.log("ERROR EMAIL= ", sendEmailNotications)
          }




        }

        else {
          console.log("ERROR SUBIENDO A S3= ", respUploadFiles3)
        }

      } else {
        console.log("ERROR VERIFY= ", respVerify)
        this.loadingApply = false //desactiva loader del boton
        Vue.$toast.open({
          message: '<p style="font-family: poppins;">Oops! Algo fallo, intenta más tarde.</p>',
          type: 'error',
          position: 'top',
          duration: 7000
        });
      }
    },
    onUpdate1(payload) {
      this.resultsPhone = payload
    },
  },

};
</script>

<style scoped>
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1904px) {
  .screensize {
    padding: 80px 0px 0px !important;
    margin-right: 240px;
    margin-left: 240px;
    /* background-color: blueviolet; */
  }

  .title-size {
    font-size: 35px;
  }
}

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 820px) {
  .screensize {
    padding: 80px 0px 0px !important;
    margin-right: 100px;
    margin-left: 100px;
    /* background-color: yellow; */
  }
  .title-size {
    font-size: 25px;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .screensize {
    padding: 80px 0px 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* background-color: red; */
  }

  .title-size {
    font-size: 25px;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 360px) {
  .screensize {
    padding: 80px 0px 0px;
    margin-right: 0px;
    margin-left: 0px;
    /* background-color: green; */
  }

  .title-size {
    font-size: 20px;
  }
}
</style>


