<template>
  <div>

    <div class="hidden-sm-and-down">
      <v-app-bar app color="white" elevation="0" height="80">
        <div style="margin-left: 240px;">
          <a :href="'https://app.pill.com.co/' + idCompany">
            <img :src="urllogo" :class="imageClass" @load="onImageLoad" ref="companyImage" alt="Company Logo" />
          </a>
        </div>
        <v-spacer></v-spacer>
      </v-app-bar>
    </div>


    <!-- for mobile-->
    <div class="hidden-md-and-up">
      <v-app-bar app color="white" elevation="0" height="80">

        <a :href="'https://app.pill.com.co/' + idCompany">
          <img :src="urllogo" :class="imageClass" @load="onImageLoad" ref="companyImage" alt="Company Logo" />
        </a>

        <v-spacer></v-spacer>

      </v-app-bar>
    </div>
  </div>
</template>


<script>
export default {
  name: 'techoportunities-',
  props: {
    urllogo: { type: String, required: true },
    idCompany: { type: String, required: true }
  },

  data() {
    return {
      imageClass: 'dynamic-image-box',
    }
  },

  methods: {

    onImageLoad(e) {
      const image = e.target
      if (image) {
        const { naturalWidth, naturalHeight } = image;
        console.log("Tamaño Imagen")
        console.log(naturalWidth, naturalHeight)
        if (naturalWidth !== naturalHeight) {
          this.imageClass = 'dynamic-image-rectangle';
        } else {
          this.imageClass = 'dynamic-image-box';
        }
      }
    },

  }
}

</script>




<style>
.dynamic-image-box {
  max-width: 75px;
  max-height: 75px;
  width: auto;
  height: auto;
}

.dynamic-image-rectangle {
  max-width: 115px;
  max-height: 80px;
  width: auto;
  height: auto;
}
</style>
