import axios from "axios";

// const ENDPOINT_PATH_3 = "https://emails-python-pill.onrender.com/api/v1/sendEmail/";

const ENDPOINT_PATH_3 = "https://pill-email-prod-backend-ebaaxsuvaa-uc.a.run.app/api/v1/sendEmail/";

export default {

  //++++++++++++++++ Metodo GET para obtener TODAS LAS OPORTUNIDADES POR "id_Company" +++++++++++++++++++++++++
  async emailNewApplicant(company, companyName, logo, role, emailCandidate) {

    const newApplicant = {
      company: company,
      companyName: companyName,
      logo: logo,
      role: role,
      emailCandidate: emailCandidate,
    }

    const resp = await axios.post(ENDPOINT_PATH_3 + "createApplication", newApplicant)
    return resp;
  },

  async welcomeCompany(nameCompany, emailCompany) {

    const newregisterCompanyNotification = {
      nameCompany: nameCompany,
      emailCompany: emailCompany
    };

    const resp = await axios.post(ENDPOINT_PATH_3 + "welcome", newregisterCompanyNotification)
    return resp;
  },

  async rejectCandidate(idCompany, logo, nameCandidate, role, emailCandidate, nameCompany) {

    const rejectedCandidateNotification = {
      idCompany: idCompany,
      logo: logo,
      nameCandidate: nameCandidate,
      role: role,
      emailCandidate: emailCandidate,
      nameCompany: nameCompany

    };

    const resp = await axios.post(ENDPOINT_PATH_3 + "rejectedCandidate", rejectedCandidateNotification)
    return resp;
  },

  async generateQRCode(urlJob) {

    const generateQRCodePayload = {
      urlJob: urlJob,
    };

    const resp = await axios.post("https://pill-email-prod-backend-ebaaxsuvaa-uc.a.run.app/api/v1/utils/generateQRCode", generateQRCodePayload)
    return resp;
  },

  async notifyFounder(user_id, description) {

    let data = JSON.stringify({
      "project": "pill",
      "event": description.includes("Promote Job") ? "Job to Promote" : 
      description.includes("Upgrade Plan") ? "New Paid Company" : 
      description.includes("Paid Plan") ? "New Paid Company" : 
      description.includes("Free Plan") ? "New Free Company" : "New event",
      "user_id": user_id,
      "description": description,
      "icon": description.includes("Promote Job") ? "💵" : 
         description.includes("Upgrade Plan") ? "🔝" : 
         description.includes("Paid Plan") ? "💸" : 
         description.includes("Free Plan") ? "🆓" : "👤",
      "notify": true,
      "channel": "subscriptions"
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.logsnag.com/v1/log',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer 784d77a61bcfb42ac5607878dfa43a9f'
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });



  }

  

}






