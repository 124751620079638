<template>
  <v-app>
    <v-col>
      <div style="padding: 50px 0px 0px">
        <router-link to="/">
          <v-img class="mx-auto" src='https://d1kz8c63g8kbpa.cloudfront.net/web/DashboardLogo.png' max-height="80" max-width="80"
            contain></v-img>
        </router-link>

        <v-card class="rounded-xl elevation-0 mx-auto mt-10" max-width="500">
          <center>
            <h2 style="text-align: center; color: black;">Ingresa a tu cuenta.</h2>
            <p style="text-align: center; font-size: small;">Ingresa tus credenciales para acceder a tu cuenta.</p>
            <br>
          </center>
          <v-card-text>
            <form ref="form" @submit.prevent="login()">
              <p class="ml-5" style="color:rgb(24, 24, 24); font-weight: 500;">Email</p>
              <v-text-field :rules="emailRules" class="mx-5" v-model="email" style="border-radius: 10px" type="email"
                required solo outlined dense color="#7579e7"></v-text-field>
              <v-row>
                  <v-col style="max-width: fit-content;">
                    <p class="ml-5" style="color:rgb(24, 24, 24); font-weight: 500;">Password</p>
                  </v-col>
                  <v-col>
                    <p class="ml-5 mr-5" style="text-align: right; color:rgb(24, 24, 24); font-weight: 400;"> <a v-on:click="goToResetPassword()" style="text-decoration: none;">¿Olvidaste tu contraseña?</a></p>
                  </v-col>
                </v-row>
              <v-text-field class="mx-5" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" v-model="password"
                :type="show ? 'text' : 'password'" @click:append="show = !show" required solo dense outlined
                color="#7579e7" style="border-radius: 10px"></v-text-field>

              <center>
                <v-btn type="submit" class="white--text mt-4" :loading="loadingLogin" :disabled="loadingLogin" rounded
                  color="#7579E7" value="log in" width="200"> Login
                </v-btn>
              </center>
              <p v-if="error" class="error" justify-center>
                incorrect User or Password.
              </p>
            </form>
          </v-card-text>
        </v-card>
        <br>
        <center>
          <p style="font-size: small; font-weight: 500; color: black;">¡Crea una cuenta! <a v-on:click="goToRegister()" style="text-decoration: underline;">Registro</a></p>
        </center>
        <br />
      </div>
    </v-col>
  </v-app>
</template>


<script>
//7579E7 color
import authFirebase from "@/logic/authFirebase"; // Importar libreria donde estan todos los metodos auht Firebase
// import alerts from "@/logic/alerts"; // Importar libreria donde estan todos los metodos auht Firebase

import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);


export default {
  name: "Login-",
  data: () => ({

    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4',
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
    ],
    email: "",
    password: "",
    error: false,
    show: false,
    loadingLogin: false,

    //Reglas para Email
    emailRules: [
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ]
  }),
  async created() {

    // Middleware para autologin si hay sesion activa
    const response = await authFirebase.verificateLoginUser()
    if (response == 'active') {
      this.$router.push("/Dashboard/main")
    }

  },
  methods: {
    goToRegister() {
      this.$router.push("/Register")
    },
    goToResetPassword() {
      this.$router.push("/PasswordRecovery")
    },
    //++++++++++++++++++++++ Metodo para Login llamado al hacer click al boton de Login +++++++++++++++++++
    async login() {
      this.loadingLogin = true; //activa loader del boton de login
      const response = await authFirebase.login(this.email, this.password);
      // console.log(response);

      // const token = await authFirebase.createTokenJWT();

      // Condiciones de fallo escritura email, password o usuario no registrado
      if (
        response == "auth/wrong-password" ||
        response == "auth/user-not-found"
      ) {
        // console.log("Por favor revisar Credeciales o usuario no rigistrado");
        // alerts.alertLoginCredentials(this.$alert)
        Vue.$toast.open({
          message: '<p style="font-family: poppins;">¡Contraseña o Usuario Incorrecto!</p>',
          type: 'error',
          position: 'top',
          duration: 6000
        });
        this.loadingLogin = false; //desactiva loader del boton de login
      } else {
        // Condicion para verificar que el correo este verificado
        if (response.emailVerified) {

          // Alerta de correo verificado y tambien direcciona al /dashboard
          // alerts.alertEmailVerificated(this.$fire, response.email, 3500, this.$router, "/dashboard/main")
          Vue.$toast.open({
            message: '<p style="font-family: poppins;">¡Bienvenido!</p>',
            type: 'success',
            position: 'top',
            duration: 4000
          });
          this.loadingLogin = false; //desactiva loader del boton de login
          this.$router.push("/dashboard/main")
        } else {
          // Alerta de correo NO verificado
          // alerts.alertEmailNOVerificated(this.$alert, response.email)
          Vue.$toast.open({
            message: '<p style="font-family: poppins;">Revisa tu bandeja de entrada y <strong>verifica</strong> tu correo electronico.</p>',
            type: 'warning',
            position: 'top',
            duration: 7000
          });
          this.loadingLogin = false; //desactiva loader del boton de login
        }
      }
    },
  },
};
</script>

<style>
.v-btn {
  text-transform: none;
}

.swal2-popup {
  font-family: 'poppins' !important;
}
</style>